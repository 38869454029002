<template>
  <div v-if="!$apollo.queries.driver.loading" class="area-approved-cnh">
    <hr>
    <b-alert :show="['CHECKING'].includes(driver.license_status)" variant="info">
      {{ $t('drivers.texts.waitingInfoDenatran') }}
    </b-alert>
    <b-alert :show="['CANCELED', 'SUSPENDED'].includes(driver.license_status)" variant="danger">
      {{ $t('drivers.texts.docImpediments') }}
    </b-alert>
    <b-alert :show="['INVALID'].includes(driver.license_status)" variant="danger">
      {{ $t('drivers.texts.docInvalid') }}
    </b-alert>
    <b-alert :show="['EXPIRED'].includes(driver.license_status)" variant="danger">
      {{ $t('drivers.texts.docExpired') }}
    </b-alert>

    <b-row>
      <!--content-->
      <b-col md="4">
        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.licenceNumber') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="driver.license_status === 'ACTIVE'" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_number || $t('drivers.texts.notInfo') }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.licencePoints') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="driver.license_points < 15" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_points }} {{ $t('drivers.labels.points') }} </strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.validate') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="['EXPIRED', 'CPF_RENTAL_DENATRAN_DONT_MATCH'].includes(driver.license_status)" class="fa fa-exclamation-triangle text-danger" />
              <i v-else class="fa fa-check-circle text-success" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_expired_at | format_date | empty }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.hasEar') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="driver.license_has_permission" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>
            {{
              ['CHECKING'].includes(driver.license_status)
                ? '-'
                : driver.license_has_permission
                  ? $t('drivers.labels.yes')
                  : $t('drivers.labels.no')
            }}
          </strong>
        </p>
      </b-col>

      <!--right content-->
      <b-col md="4">
        <p class="card-text">
          <span class="text-muted">{{ $t('drivers.labels.stateRegister') }}</span>
          <br>
          <strong>{{ driver.license_state | empty }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.timeLicence') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="this.$moment().diff(driver.license_first_issued_at, 'years') > 0" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_first_issued_at | format_from_now | empty }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.category') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="['B', 'AB', 'C', 'D', 'E'].includes(driver.license_category)" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_category | empty }}</strong>
        </p>
      </b-col>
    </b-row>
  </div>
  <div v-else class="mt-4">
    <b-row>
      <b-alert show variant="waning" class="text-center">
        {{ $t('drivers.labels.chargingLicense') }}
      </b-alert>
    </b-row>
    <b-progress :value="100" variant="warning" animated />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { i18next } from '../../../translate/i18n';

export default {
  name: 'DriverViewApprovalCnh',
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    document_title: i18next.t('drivers.titles.documentTitle'),
    upload_success: i18next.t('drivers.texts.uploadFileSuccess'),
    upload_error: i18next.t('drivers.texts.uploadFileError'),
    approve_success: i18next.t('drivers.titles.docsApproved'),
    approve_error: i18next.t('drivers.texts.approveError'),
    reprove_success: i18next.t('drivers.titles.docReproved'),
    reprove_error: i18next.t('drivers.texts.errorReprovedDocs')
  }),
  apollo: {
    driver: {
      query: gql `
        query DriverGET($id: ID!) {
          driver(id: $id) {
            id
            cpf
            name
            mother_name
            father_name
            license_number
            license_first_issued_at
            license_expired_at
            license_category
            license_points
            license_status
            license_status_detail
            license_remarks
            license_has_permission
            license_state
          }
        }
      `,
      variables() {
        return {
          id: this.driverId,
        };
      },
      update(data) {
        const {
          driver
        } = data;
        return driver;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.area-approved-cnh {
    z-index: 1;
}

.section-title {
    font-size: 12pt;
}

.cnh-photo {
    background-color: #d7d7d7;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    max-height: 185px;

    img {
        max-width: 100%;
    }
}

.label-inverse {
    p {
        margin-bottom: 3px;
    }
}

.rides {
    font-size: 11pt;
}

.text-line-height {
    p {
        margin-bottom: 3px;
    }
}

.status {
    margin-top: -28px;
    z-index: 0;
}
</style>
