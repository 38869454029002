var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !!_vm.profile.score == false
      ? _c(
          "div",
          [
            _c(
              "b-badge",
              {
                staticClass: "mr-2 mt-1 float-left user-score",
                attrs: { id: "popover-kovi-score", variant: "default" },
              },
              [_vm._v(" - ")]
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "popover-kovi-score",
                  triggers: "hover",
                  placement: "bottom",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _vm._v(" Kovi Karma"),
                          _c("sup", [_vm._v("Beta")]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2285471205
                ),
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("drivers.karma.no_transactions")) + " "
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    !!_vm.profile.score
      ? _c(
          "div",
          [
            _c(
              "b-badge",
              {
                staticClass: "mr-2 mt-1 float-left user-score",
                attrs: {
                  id: "popover-kovi-score",
                  variant: _vm.checkScore(_vm.profile.score.kovi_score),
                },
              },
              [_vm._v(" " + _vm._s(_vm.profile.score.kovi_score) + " ")]
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "popover-kovi-score",
                  triggers: "hover",
                  placement: "bottom",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _vm._v(" Kovi Karma"),
                          _c("sup", [_vm._v("Beta")]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2285471205
                ),
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("drivers.karma.paid_on_time")) + ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.profile.transaction_summary.total_transactions_on_time
                    )
                  ),
                ]),
                _vm._v(
                  "/" +
                    _vm._s(_vm.profile.transaction_summary.total_transactions)
                ),
                _c("br"),
                _c("hr"),
                _vm._v(
                  " " + _vm._s(_vm.$t("drivers.karma.paid_overdue")) + ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.profile.transaction_summary.total_transactions_overdue
                    )
                  ),
                ]),
                _vm._v(
                  "/" +
                    _vm._s(_vm.profile.transaction_summary.total_transactions)
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("drivers.karma.probably_to_overdue")) +
                    ": "
                ),
                _c("strong", [
                  _vm._v(_vm._s(100 - _vm.profile.score.kovi_score) + "%"),
                ]),
                _c("br"),
                _vm.profile.transaction_summary.avg_transactions_overdue_days
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("drivers.karma.avg_overdue")) + ": "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.profile.transaction_summary
                              .avg_transactions_overdue_days
                          ) +
                            " " +
                            _vm._s(
                              _vm.profile.transaction_summary
                                .avg_transactions_overdue_days === 1
                                ? _vm.$t("drivers.karma.avg_overdue_day")
                                : _vm.$t("drivers.karma.avg_overdue_days")
                            )
                        ),
                      ]),
                      _c("br"),
                    ])
                  : _vm._e(),
                _c("hr"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("drivers.karma.total_recurrencies")) +
                    ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("money")(
                        _vm.profile.transaction_summary.debit_recurrencies
                      )
                    ) + " "
                  ),
                ]),
                _c("br"),
                _vm._v(
                  " " + _vm._s(_vm.$t("drivers.karma.total_fines")) + ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("money")(
                        _vm.profile.transaction_summary.debit_fines
                      )
                    ) + " "
                  ),
                ]),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("drivers.karma.total_maintenances")) +
                    ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("money")(
                        _vm.profile.transaction_summary.debit_maintenances
                      )
                    ) + " "
                  ),
                ]),
                _c("br"),
                _vm._v(
                  " " + _vm._s(_vm.$t("drivers.karma.total_others")) + ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("money")(
                        _vm.profile.transaction_summary.debit_others
                      )
                    ) + " "
                  ),
                ]),
                _c("br"),
                _c("hr"),
                _vm._v(
                  " " + _vm._s(_vm.$t("drivers.karma.total_debits")) + ": "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("money")(
                        _vm.profile.transaction_summary.debit_total
                      )
                    ) + " "
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }