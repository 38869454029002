<template>
  <div v-if="driver">
    <driver-header :id="$route.params.id" />
    <!--show only driver status is PENDING_DOCS, REVIEW_DOCS-->
    <div v-if="driver.status === 'REJECTED'">
      <rejects :driver-id="$route.params.id" />
    </div>
    <div v-else>
      <docs
        v-if="['APPS_PENDING', 'DOCS_CHECKING', 'DOCS_PENDING', 'DOCS_REJECTED', 'BGC_REJECTED'].includes(driver.step)"
        :driver-id="$route.params.id"
      />
      <!-- <payments v-if="['PAYMENT_PENDING', 'PAYMENT_PENDING_PAYMENT'].includes(driver.step)" :driverId="$route.params.id" :modal="true" /> -->
      <!-- <schedules v-if="['DELIVERY_SCHEDULED', 'DELIVERY_NOSHOW'].includes(driver.step)" :driverId="$route.params.id" /> -->
    </div>
  </div>
</template>
<style lang="scss" scoped></style>

<script>
import DriverHeader from '@components/drivers/header';
import gql from 'graphql-tag';
import Docs from './docs';
// import Payments from './payments';
// import Schedules from './schedules';
import Rejects from './rejects';

export default {
  components: {
    DriverHeader,
    Docs,
    // Payments,
    // Schedules,
    Rejects,
  },
  data() {
    return {};
  },
  computed: {
    driver() {
      return this.$store.getters['driver/driver'];
    },
  },
  mounted() {
    this.$store.dispatch('driver/byGql', {
      gql: gql`
        query DriverGet($id: ID!) {
          driver(id: $id) {
            id
            step
            status
          }
        }
      `,
      variables: {
        id: this.$route.params.id,
      },
    });
  },
};
</script>
