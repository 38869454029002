<script>
import DRIVER_TRIPS from '@graphql/driver/queries/get-trips.gql';

export default {
  name: 'DriverContainerHistoryApps',
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    driver_trips: {
      query: DRIVER_TRIPS,
      variables() {
        return {
          driver: this.driverId,
        };
      },
      update: data => {
        return data.tripsByDriver;
      },
      result(data) {
        if (!data.loading && data.data.tripsByDriver.items) {
          let rides = this.groupBy(data.data.tripsByDriver.items, 'service');
          this.dataShared = [];

          for (const ride in rides) {
            this.dataShared.push({
              name: ride,
              status: rides[ride] ? 'Ativo' : 'Inativo',
              rides: rides[ride] ? rides[ride].length : 0,
              lastNDays: '--',
              approved: true,
            });
          }

          this.$emit('shared', this.dataShared);
        }
      },
    },
  },
  methods: {
    groupBy(trips, field) {
      return trips.reduce(function (acc, obj) {
        let key = obj[field];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
  },
};
</script>

<template v-if="dataShared && dataShared.length >
0"
>
  <div>
    <slot />
  </div>
</template>
