var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center mt-5" },
    [
      _c("hr"),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.driver-reject-modal",
              modifiers: { "driver-reject-modal": true },
            },
          ],
          staticClass: "pl-3 pr-3 mr-3",
          attrs: { id: "btn-reject-driver", size: "md", variant: "dark" },
        },
        [_vm._v(" " + _vm._s(_vm.$t("drivers.buttons.driverReject")) + " ")]
      ),
      _vm.enabledApproveDriverButton
        ? _c(
            "b-button",
            {
              staticClass: "pl-3 pr-3 mr-3",
              attrs: { id: "btn-approve-docs", size: "md", variant: "success" },
              on: { click: _vm.approveDriver },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("drivers.buttons.driverApprove")) + " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "driver-reject-modal",
            size: "md",
            title: _vm.$t("drivers.titles.driverReject"),
            "cancel-title": _vm.$t("drivers.buttons.cancel"),
            "ok-title": _vm.$t("drivers.buttons.driverReject"),
            "ok-disabled": !_vm.driverRejectSelect,
            "ok-variant": "danger",
          },
          on: {
            ok: function ($event) {
              return _vm.disapproveDriver()
            },
          },
        },
        [
          _c("b-form-select", {
            attrs: { options: _vm.driverRejectOptions },
            model: {
              value: _vm.driverRejectSelect,
              callback: function ($$v) {
                _vm.driverRejectSelect = $$v
              },
              expression: "driverRejectSelect",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }