<script>
import MapGoogleMaps from '@components/shared/map-google-maps';
import BadgeDocStatus from '@components/drivers/proof-docs/badge-doc-status';
import ModalShowImage from '@components/drivers/proof-docs/modal-show-image';
import gql from 'graphql-tag';

export default {
  name: 'DriverViewApprovalAddress',
  components: {
    MapGoogleMaps,
    BadgeDocStatus,
    ModalShowImage,
  },
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return{
      document_title: this.$t('drivers.titles.AddressTitle'),
    };
  },
  computed: {
    addressDocs() {
      return this.$store.getters['driverDocument/address'];
    },
    rejectReasons() {
      if (!this.addressDocs[0].reject_reasons) return [];

      return this.addressDocs[0].reject_reasons;
    }
  },
  apollo: {
    driver: {
      query: gql `
        query DriverGET($id: ID!) {
          driver(id: $id) {
            id
            name
            mother_name
            father_name
            address_street_name
            address_street_number
            address_street_details
            address_neighborhood
            address_city
            address_state
            address_postal_code
            address_country
            address_latlong
            updated_at
          }
        }
      `,
      variables() {
        return {
          id: this.driverId,
        };
      },
      update(data) {
        const {
          driver
        } = data;
        return driver;
      },
    },
  },
  mounted() {
    this.status = this.addressStatus;
  },
  methods: {
    loadDocs() {
      this.$store.dispatch('driverDocument/getByDriverId', {
        driverId: this.driverId,
        conditionals: {
          status: ['PENDING', 'APPROVED', 'REJECTED', 'SENT'],
        },
      });
    },
    async uploadDoc(file) {
      try {
        const objectUpload = await this.$store.dispatch('driverDocument/uploadAddress', {
          driverId: this.driverId,
          file,
        });
        if (objectUpload) {
          if (objectUpload.status === 'success') {
            this.$refs.driver_approval_address_file.reset();
            this.$snotify.success(this.$t('drivers.texts.uploadFileSuccess'), this.document_title);
            this.loadDocs();
          } else {
            this.$snotify.error(this.$t('drivers.texts.uploadFileError'), this.document_title);
          }
        }
      } catch (e) {
        this.$snotify.error(this.$t('drivers.texts.uploadFileError'), this.document_title);
      }
    },

    async approveDoc(documentId) {
      try {
        await this.$store.dispatch('driverDocument/approve', documentId);
        this.$snotify.success(this.$t('drivers.titles.docsApproved'), this.document_title);
        this.loadDocs();
      } catch (e) {
        this.$snotify.error(this.$t('drivers.texts.approveError'), this.document_title);
      }
    },

    async disapproveDoc(documentId) {
      try {
        await this.$store.dispatch('driverDocument/disapprove', documentId);
        this.$snotify.error(this.$t('drivers.titles.docReproved'), this.document_title);
        this.$router.push({
          name: 'drivers-signup-docs-checking',
        });
      } catch (e) {
        this.$snotify.error(this.$t('drivers.texts.errorReprovedDocs'), this.document_title);
      }
    },

    getFullAddress() {
      return [
        this.driver.address_street_name,
        this.driver.address_street_number,
        this.driver.address_neighborhood,
        this.driver.address_city,
        this.driver.address_state,
        this.driver.address_postal_code,
        this.driver.address_country,
      ].join(', ');
    },
    getLocation() {
      return {
        id: this.driver.id,
        label: 'Endereço de Residência',
        location: this.driver.address_latlong ? this.driver.address_latlong.split(',') : [],
        address: this.getFullAddress(),
      };
    }
  },
};
</script>

<template>
  <div v-if="!$apollo.queries.driver.loading">
    <hr>
    <b-row class="mb-4">
      <b-col class="header">
        <h4 class="mt-1">
          {{ $t('drivers.titles.residenceTitle') }}
          <badge-doc-status parent-component="address" :status="addressDocs.length ? addressDocs[0].status : 'PENDING'" />
        </h4>
      </b-col>
    </b-row>

    <p class="card-text mb-4">
      <span class="text-muted">{{ $t('drivers.labels.driverName') }}</span>
      <br>
      <strong v-if="driver.name">{{ driver.name }}</strong>
      <strong v-else>{{ $t('drivers.texts.stillNotInfo') }}</strong>
    </p>

    <p class="card-text mb-4">
      <span class="text-muted">{{ $t('drivers.labels.motherName') }}</span>
      <br>
      <strong v-if="driver.mother_name">{{ driver.mother_name }}</strong>
      <strong v-else>{{ $t('drivers.texts.stillNotInfo') }}</strong>
    </p>

    <p class="card-text mb-4">
      <span class="text-muted">{{ $t('drivers.labels.fatherName') }}</span>
      <br>
      <strong v-if="driver.father_name">{{ driver.father_name }}</strong>
      <strong v-else>{{ $t('drivers.texts.stillNotInfo') }}</strong>
    </p>

    <p v-if="addressDocs.length && addressDocs[0].reject_reasons" class="card-text mb-4">
      <strong v-html="$t('drivers.texts.rejectedReason') + ':'" />
      <br>
      <ul>
        <li
          v-for="(reason, index) in addressDocs[0].reject_reasons"
          :key="index"
          v-html="$t(`drivers.forms.documentRejectOptions.${reason}`)"
        />
      </ul>
    </p>

    <p class="card-text mb-4">
      <span class="text-muted">{{ $t('drivers.labels.address') }}</span>
      <br>
      <strong v-if="driver.address_street_name && driver.address_street_number">{{ getFullAddress() }}</strong>
      <strong v-else>{{ $t('drivers.texts.stillNotInfo') }}</strong>
    </p>

    <b-row>
      <!--address photo-->
      <b-col md="4">
        <modal-show-image :url="addressDocs.length && addressDocs[0].url ? addressDocs[0].url : null" :title="getFullAddress()" :display="addressDocs.length > 0 && addressDocs[0].url ? 1 : 0" />
      </b-col>

      <!--street view-->
<!--      <b-col md="4">-->
<!--        <img-street-view :display="driver.address_street_name && driver.address_street_number ? 1 : 0" :address="getFullAddress()" />-->
<!--      </b-col>-->

      <!-- google maps -->
      <b-col md="8">
        <map-google-maps label="Endereço de residência" name="driver-address" :display="driver.address_street_name && driver.address_street_number ? 1 : 0"
                         :driver="this.getLocation()"
        />
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col md="4">
        <b-form-group :label="$t('drivers.labels.chargeFile')">
          <b-form-file id="input-files-address" ref="driver_approval_address_file" accept="image/jpeg, image/png"
                       :placeholder="$t('drivers.labels.choiceFile')" @input="uploadDoc"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
  <div v-else class="mt-4">
    <b-row>
      <b-alert show variant="waning" class="text-center">
        {{ $t('drivers.labels.chargingAddress') }}
      </b-alert>
    </b-row>
    <b-progress :value="100" variant="warning" animated />
  </div>
</template>

<style lang="scss" scoped>
.section-title {
    font-size: 12pt;
}

.address-photo {
    background-color: #d7d7d7;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    max-height: 200px;

    img {
        max-width: 100%;
    }
}

.address-street-view {
    max-width: 100%;
    overflow: hidden;
    height: 185px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

/*.custom-file-input:lang(en) ~ .custom-file-label::after {*/
.custom-file-input ~ .custom-file-label::after {
    content: 'Procurar';
}
</style>
