<script>
export default {
  name: 'DriverViewApprovalHistoryApps',
  props: {
    listApps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <hr>
    <h4 class="mt-1 mb-4">
      {{ $t('drivers.titles.historyRacing') }}
    </h4>
    <b-row>
      <b-col v-for="(app, index) of listApps" :key="index" class="app-item mb-4"
             md="4"
      >
        <b-row>
          <b-col md="4">
            <b-alert class="rides mb-1 text-center" show :variant="app.approved ? 'success' : 'danger'">
              {{ app.rides }}
            </b-alert>
            <div class="small text-center text-muted">
              {{ $t('drivers.titles.driversRacing') }}
            </div>
          </b-col>
          <b-col md="8" class="pl-0">
            <h4 class="mt-2 mb-0 font-weight-bold">
              {{ app.name }}
            </h4>
            <p class="small text-uppercase text-muted">
              {{ $t('drivers.titles.drivers') }} {{ app.status }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.rides {
    font-size: 1.8em;
}
.app-status {
    font-size: 1em;
    line-height: 1em;
}
</style>
