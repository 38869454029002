var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-badge",
    {
      staticClass: "text-badge",
      attrs: { id: _vm.getId, variant: _vm.variantStatus },
    },
    [_vm._v(" " + _vm._s(_vm.status) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }