<template>
  <div class="address-photo">
    <img v-if="display" v-viewer="options" :src="url"
         :alt="title"
    >
    <div v-else>
      Ainda não recebemos o comprovante
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalShowImage',
  props: {
    url: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    display: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    modal: false,
    options: {
      navbar: false,
      inline: false,
      button: true,
      title: [4, image => `${image.alt}`],
      loop: false,
      toolbar: {
        zoomIn: {
          show: true,
          size: 'large',
        },
        zoomOut: {
          show: true,
          size: 'large',
        },
        oneToOne: 0,
        reset: {
          show: true,
          size: 'large',
        },
        prev: 0,
        play: 0,
        next: 0,
        rotateLeft: {
          show: true,
          size: 'large',
        },
        rotateRight: {
          show: true,
          size: 'large',
        },
        flipHorizontal: 0,
        flipVertical: 0,
      },
      tooltip: false,
      zoomable: true,
      rotatable: true,
      scalable: false,
      transition: false,
      keyboard: true,
      fullscreen: false,
      url: 'data-source',
    },
  }),
};
</script>

<style lang="scss">
.viewer-title {
    color: #fff;
    font-size: 16px;
    margin: 0 5% 10px;
    opacity: 1;
}
</style>
<style lang="scss" scoped>
.address-photo {
    cursor: pointer;
    background-color: #d7d7d7;
    border-radius: 3px;
    overflow: hidden;
    max-height: 200px;
    text-align: center;

    > div {
        display: block;
        height: 200px;
        vertical-align: middle;
        text-align: center;
        cursor: default;
        padding-top: 90px;
        color: #666;
        font-size: 1.2em;
    }

    img {
        max-width: 100%;
    }
}
</style>
