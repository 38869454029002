var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.driver
    ? _c(
        "div",
        [
          _c("driver-header", { attrs: { id: _vm.$route.params.id } }),
          _vm.driver.status === "REJECTED"
            ? _c(
                "div",
                [
                  _c("rejects", {
                    attrs: { "driver-id": _vm.$route.params.id },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  [
                    "APPS_PENDING",
                    "DOCS_CHECKING",
                    "DOCS_PENDING",
                    "DOCS_REJECTED",
                    "BGC_REJECTED",
                  ].includes(_vm.driver.step)
                    ? _c("docs", {
                        attrs: { "driver-id": _vm.$route.params.id },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }