var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$apollo.queries.driver.loading
    ? _c(
        "div",
        { staticClass: "area-approved-cnh" },
        [
          _c("hr"),
          _c(
            "b-alert",
            {
              attrs: {
                show: ["CHECKING"].includes(_vm.driver.license_status),
                variant: "info",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("drivers.texts.waitingInfoDenatran")) + " "
              ),
            ]
          ),
          _c(
            "b-alert",
            {
              attrs: {
                show: ["CANCELED", "SUSPENDED"].includes(
                  _vm.driver.license_status
                ),
                variant: "danger",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("drivers.texts.docImpediments")) + " ")]
          ),
          _c(
            "b-alert",
            {
              attrs: {
                show: ["INVALID"].includes(_vm.driver.license_status),
                variant: "danger",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("drivers.texts.docInvalid")) + " ")]
          ),
          _c(
            "b-alert",
            {
              attrs: {
                show: ["EXPIRED"].includes(_vm.driver.license_status),
                variant: "danger",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("drivers.texts.docExpired")) + " ")]
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "4" } }, [
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("drivers.labels.licenceNumber")) + " "
                    ),
                    _vm.driver.license_status !== "CHECKING"
                      ? _c("span", [
                          _vm.driver.license_status === "ACTIVE"
                            ? _c("i", {
                                staticClass: "fa fa-check-circle text-success",
                              })
                            : _c("i", {
                                staticClass:
                                  "fa fa-exclamation-triangle text-danger",
                              }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.driver.license_number ||
                          _vm.$t("drivers.texts.notInfo")
                      )
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("drivers.labels.licencePoints")) + " "
                    ),
                    _vm.driver.license_status !== "CHECKING"
                      ? _c("span", [
                          _vm.driver.license_points < 15
                            ? _c("i", {
                                staticClass: "fa fa-check-circle text-success",
                              })
                            : _c("i", {
                                staticClass:
                                  "fa fa-exclamation-triangle text-danger",
                              }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.driver.license_points) +
                        " " +
                        _vm._s(_vm.$t("drivers.labels.points")) +
                        " "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("drivers.labels.validate")) + " "
                    ),
                    _vm.driver.license_status !== "CHECKING"
                      ? _c("span", [
                          [
                            "EXPIRED",
                            "CPF_RENTAL_DENATRAN_DONT_MATCH",
                          ].includes(_vm.driver.license_status)
                            ? _c("i", {
                                staticClass:
                                  "fa fa-exclamation-triangle text-danger",
                              })
                            : _c("i", {
                                staticClass: "fa fa-check-circle text-success",
                              }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("empty")(
                          _vm._f("format_date")(_vm.driver.license_expired_at)
                        )
                      )
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(" " + _vm._s(_vm.$t("drivers.labels.hasEar")) + " "),
                    _vm.driver.license_status !== "CHECKING"
                      ? _c("span", [
                          _vm.driver.license_has_permission
                            ? _c("i", {
                                staticClass: "fa fa-check-circle text-success",
                              })
                            : _c("i", {
                                staticClass:
                                  "fa fa-exclamation-triangle text-danger",
                              }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          ["CHECKING"].includes(_vm.driver.license_status)
                            ? "-"
                            : _vm.driver.license_has_permission
                            ? _vm.$t("drivers.labels.yes")
                            : _vm.$t("drivers.labels.no")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("b-col", { attrs: { md: "4" } }, [
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("drivers.labels.stateRegister"))),
                  ]),
                  _c("br"),
                  _c("strong", [
                    _vm._v(_vm._s(_vm._f("empty")(_vm.driver.license_state))),
                  ]),
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("drivers.labels.timeLicence")) + " "
                    ),
                    _vm.driver.license_status !== "CHECKING"
                      ? _c("span", [
                          this.$moment().diff(
                            _vm.driver.license_first_issued_at,
                            "years"
                          ) > 0
                            ? _c("i", {
                                staticClass: "fa fa-check-circle text-success",
                              })
                            : _c("i", {
                                staticClass:
                                  "fa fa-exclamation-triangle text-danger",
                              }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("empty")(
                          _vm._f("format_from_now")(
                            _vm.driver.license_first_issued_at
                          )
                        )
                      )
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("drivers.labels.category")) + " "
                    ),
                    _vm.driver.license_status !== "CHECKING"
                      ? _c("span", [
                          ["B", "AB", "C", "D", "E"].includes(
                            _vm.driver.license_category
                          )
                            ? _c("i", {
                                staticClass: "fa fa-check-circle text-success",
                              })
                            : _c("i", {
                                staticClass:
                                  "fa fa-exclamation-triangle text-danger",
                              }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm._f("empty")(_vm.driver.license_category))
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: { show: "", variant: "waning" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("drivers.labels.chargingLicense")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("b-progress", {
            attrs: { value: 100, variant: "warning", animated: "" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }