<template>
  <div>
    <driver-license-alert :driverId="id" />
    <div v-if="profile">
      <b-row class="pb-4 justify-content-start">
        <b-col md="8" sm="12">
          <driver-karma
            v-if="profile.status !== 'SIGNUP'"
            :profile="profile"
          />
          <b-img
            v-b-modal.modalDriverPhoto
            :src="profile.photo"
            rounded
            blank-color="#C0C0C0"
            :title="$t('drivers.labels.showImage')"
            class="mr-2 mt-1 float-left user-profile"
          />
          <h2 class="card-title m-0 mb-1">
            <b-link
              :to="`/drivers/profile/${newDriverId}`"
              class="card-name mr-1"
              :title="profile.name"
            >
              {{ profile.name || $t('drivers.texts.anonymous') }}
            </b-link>
          </h2>
          <div v-if="isActiveDriver" class="small text-muted">
            {{ $t('drivers.labels.status') }}:
            <b-badge :variant="setStatusBadge(profile.status)">
              {{ profile.status }}
            </b-badge>
            -
            {{ $t('drivers.labels.register') }}
            <span
              v-if="profile.created_at"
            >{{ profile.created_at | moment('from', true) }}</span>
            <span v-else>0 {{ $t('drivers.labels.days') }}</span>
            {{ $t('drivers.labels.behind') }}
          </div>
          <div v-else class="small text-muted">
            {{ $t('drivers.labels.situationRegister') }}:
            <b-badge v-if="profile.status === 'BANNED'" variant="danger" class="text-uppercase">
              {{ $t('drivers.labels.banned') | up }}
            </b-badge>
            <b-badge v-else variant="warning">
              {{ profile.step }}
            </b-badge>
            -
            {{ $t('drivers.labels.register') }}
            <span
              v-if="profile.created_at"
            >{{ profile.created_at | moment('from', true) }}</span>
            <span v-else>0 {{ $t('drivers.labels.days') }}</span>
            {{ $t('drivers.labels.behind') }}
          </div>
        </b-col>
        <b-col
          v-if="resetProfile"
          md="4"
          sm="12"
          class="text-md-right text-sm-center"
        >
          <b-button
            v-if="$route.query.redir && !modal"
            class="mr-2"
            variant="secondary"
            data-test="driver-header__btn-back"
            @click="goBack"
          >
            <i class="fa fa-angle-left" />
            {{ $t('drivers.buttons.back') }}
          </b-button>
          <b-button
            v-if="['SIGNUP'].includes(profile.status)"
            :href="'https://web.whatsapp.com/send?phone=' + profile.phone_number"
            target="_blank"
            class="bt-whatsapp mr-2"
            :itle="$t('drivers.labels.sendWhats')"
            data-test="driver-header__btn-whatsapp"
          >
            <i class="fa fa-whatsapp" />
            {{ $t('drivers.buttons.whatsApp') }}
          </b-button>

          <b-button-group class="mr-2">
            <!-- link to drive profile at signup flow -->
            <b-button
              v-if="['LEAD', 'PROSPECT', 'DOCS_PENDING', 'DOCS_REJECTED', 'DOCS_CHECKING'].includes(profile.step)"
              variant="secondary"
              :to="`/drivers/profile/${newDriverId}`"
              data-test="driver-header__btn-account"
            >
              {{ $t('drivers.buttons.profile') }}
            </b-button>
            <b-button
              v-if="profile.booking && profile.booking.car"
              :to="`/fleet/${profile.booking.car.id}`"
              data-test="driver-header__btn-car"
            >
              {{ $t('drivers.buttons.car') }}
            </b-button>
            <b-button
              data-test="driver-header__btn-edit"
              @click.stop="goTo()"
            >
              {{ $t('drivers.buttons.edit') }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row v-if="!newScheduleModal && ['ACTIVE', 'INACTIVE', 'BANNED', 'REJECTED'].includes(profile.status) && resetProfile">
        <b-col md="12" sm="12" class="text-right driver-nav">
          <b-nav tabs align="left">
            <b-nav-item
              :to="`/drivers/profile/${newDriverId}/bookings`"
              data-test="driver-header__btn-bookings"
            >
              {{ $i18n.t('driversBooking.words.location') }}
            </b-nav-item>
            <b-nav-item
              :to="`/drivers/profile/${newDriverId}/wallet`"
              data-test="driver-header__btn-wallet"
            >
              {{ $t('payments.labels.wallet') }}
            </b-nav-item>
            <b-nav-item
              :to="`/drivers/profile/${newDriverId}/fines`"
              data-test="driver-header__btn-fines"
            >
              Multas
              <b-badge variant="light">
                {{ profile.total_fines }}
              </b-badge>
            </b-nav-item>
            <b-nav-item-dropdown
              id="driver-messages"
              text="Mensagem"
              toggle-class="nav-link-custom"
              left
            >
              <b-dropdown-item
                :href="'https://web.whatsapp.com/send?phone=' + profile.phone_number"
                target="_blank"
                data-test="driver-header__select-whatsapp"
              >
                <i class="fa fa-whatsapp" />
                WhatsApp
              </b-dropdown-item>
              <b-dropdown-item data-test="driver-header__select-sms" @click="newMessage()">
                <i class="fa fa-comments" />
                SMS+Email
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown
              v-if="checkScheduleView({ show: 'RULE:SCHEDULE:VIEW'})"
              id="driver-messages"
              left
              text="Agendamento"
              toggle-class="nav-link-custom"
            >
              <b-dropdown-item
                data-test="driver-header__select-whatsapp"
                @click="openModalDeliveryCarSchedule"
              >
                {{ $i18n.t('drivers.titles.delivery') }}
              </b-dropdown-item>
              <b-dropdown-item
                data-test="driver-header__select-whatsapp"
                @click="openModalDevolutionCarSchedule"
              >
                {{ $i18n.t('drivers.titles.devolution') }}
              </b-dropdown-item>
              <b-dropdown-item
                data-test="driver-header__select-whatsapp"
                @click="openModalDeliveryAndDevolutionCarSchedule"
              >
                {{ $i18n.t('drivers.titles.deliveryAndDevolution') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav>
        </b-col>
      </b-row>

      <b-modal
        id="modalDriverPhoto"
        lazy
        no-fade
        size="md"
        :title="profile.name"
        :hide-footer="true"
        body-class="p-1"
      >
        <b-img
          :src="profile.photo"
          rounded="bottom"
          fluid-grow
          blank-color="#C0C0C0"
          :alt="profile.name"
        />
      </b-modal>
      <contact-driver :modal_data="contact_modal_data" />

      <b-card v-if="newScheduleModal || ['SIGNUP'].includes(profile.status) || !resetProfile">
        <b-row>
          <b-col md="3" sm="12">
            <p class="card-text">
              <span class="text-muted">{{ $t('drivers.labels.lastUpdate') }}</span>
              <br>
              <strong>{{ profile.updated_at | format_from_now }}</strong>
            </p>
          </b-col>

          <b-col md="3" sm="12">
            <p class="card-text">
              <span class="text-muted">
                {{ $t('drivers.labels.phone') }}
                <i
                  v-if="profile.phone_number_verified"
                  class="fa fa-check-circle text-success"
                />
                <i v-else class="fa fa-exclamation-triangle text-danger" />
              </span>
              <br>
              <strong>{{ profile.phone_number | format_phone }}</strong>
            </p>
          </b-col>

          <b-col md="3" sm="12">
            <p class="card-text">
              <span class="text-muted">
                {{ $t('drivers.labels.email') }}
                <i
                  v-if="profile.email_verified"
                  class="fa fa-check-circle text-success"
                />
                <i v-else class="fa fa-exclamation-triangle text-danger" />
              </span>
              <br>
              <strong>{{ profile.email }}</strong>
            </p>
          </b-col>

          <b-col md="3" sm="12">
            <p class="card-text">
              <span class="text-muted">{{ $t('drivers.labels.document') }}</span>
              <br>
              <strong>{{ profile.cpf | format_document }}</strong>
            </p>
            <b-badge
              v-for="obj in usersAcessingDrivers.filter(u => u.driverId === id)"
              :key="obj.id"
              variant="warning"
              :title="obj.email"
            >
              {{ obj.email.split('@')[0] }}
            </b-badge>
            <br>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { formatStatus, leadStatus } from '@utils/driver';
  import ContactDriver from '@components/modals/contact-driver';
  import DriverLicenseAlert from "@components/shared/driver-license-alert.vue";
  import DriverKarma from './driver-karma';

  export default {
    name: 'DriverHeader',
    components: {
      ContactDriver,
      DriverKarma,
      DriverLicenseAlert,
    },
    props: {
      modal: {
        type: Boolean,
        required: false,
        default: false,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      resetProfile: {
        type: Boolean,
        default: true
      },
      newScheduleModal : {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        usersAcessingDrivers: [
          {
            driverId: this.$route.params.id,
            email: this.$root.$store.state.user.attributes.email,
          },
        ],
        contact_modal_data: {
          id: null,
          phone_number: 'teste',
          sms_text: '',
          email_title: '',
          email_body: '',
          email_address: '',
        },
      };
    },
    computed: {
      isActiveDriver() {
        return (
          this.profile.status == 'ACTIVE' ||
          this.profile.status == 'INACTIVE'
        )
      },
      profile() {
        return this.$store.getters['driver/header'];
      },
      driverId() {
        return this.$route.params.id;
      },
      newDriverId() {
        // Usado para casos para quando o id do driver nao esta na rota (Modal nova de schedule é um dos casos)
        if (this.driverId) {
          return this.$route.params.id;
        } else {
          return this.profile.id
        }
      }
    },
    mounted() {
      this.loadProfile();

      const it = this;
      it.$pubsub('driversList').subscribe(data => {
        const list = [...it.usersAcessingDrivers];
        for (const item of Array.from(data)) {
          if (
            item.action === 'enter' &&
            !list.some(
              u => u.driverId === item.driverId && u.email === item.email
            )
          ) {
            list.push({ driverId: item.driverId, email: item.email });
          } else if (item.action === 'exit') {
            const idx = list.findIndex(
              u => u.driverId === item.driverId && u.email === item.email
            );
            if (idx >= 0) list.splice(idx, 1);
          }
        }
        it.usersAcessingDrivers = [...list];
      });
      it.$pubsub('driversList').publish({
        action: 'enter',
        driverId: this.driverId,
        email: this.$root.$store.state.user.attributes.email,
      });
    },
    beforeDestroy() {
      this.$pubsub('driversList').publish({
        action: 'exit',
        driverId: this.driverId,
        email: this.$root.$store.state.user.attributes.email,
      });
    },
    methods: {
      goTo() {
        this.$router.push({
          path: `/drivers/profile/${this.newDriverId}/edit`,
        });
        // if (leadStatus.includes(status)) {
        //   this.$router.push({
        //     path: `/drivers/profile/${this.driverId}/lead-edit`,
        //   });
        // } else {
        //   this.$router.push({
        //     path: `/drivers/profile/${this.driverId}/edit`,
        //   });
        // }
      },
      goBack() {
        if (this.$route.query.redir) {
          this.$router.push({
            path: this.$route.query.redir,
          });
        } else {
          this.$router.push({
            path: `/drivers/profile/${this.driverId}`,
          });
        }
      },
      setStatusBadge(status) {
        return formatStatus(status);
      },
      newMessage() {
        this.contact_modal_data.id = this.driverId;
        this.$root.$emit('bv::close::modal');
        this.$root.$emit('bv::show::modal', 'modalContact');
      },
      loadProfile() {
        this.$store.dispatch('driver/clearHeader');
        this.$store.dispatch('driver/header', {
          id: this.id,
        });
      },
      openModalDeliveryCarSchedule() {
        this.$root.$emit('bv::show::modal', 'deliveryCarSchedule');
      },
      openModalDevolutionCarSchedule() {
        this.$root.$emit('bv::show::modal', 'devolutionCarSchedule');
      },
      openModalDeliveryAndDevolutionCarSchedule(){
        this.$root.$emit('bv::show::modal', 'deliveryAndDevolutionCarSchedule');
      },

      // TODO: Bad code, please fix it... (Costa)
      checkScheduleView(e) {
        const groups = this.$store.getters['user/groups'];
        if (e.show) {
          if (groups.includes(e.show)) return true;
          return false;
        }
        return true;
      },
    },
  };
</script>

<style style="scss" scoped>
  .driver-nav .nav-tabs {
    margin-bottom: 10px !important;
  }

  .driver-nav .nav-tabs .nav-link {
    line-height: 18px;
  }

  .driver-nav .nav-tabs .nav-link.active {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-bottom: 1px solid #f0f0f0;
  }

  .driver-nav .nav-tabs .nav-link:hover {
    border-bottom: 1px solid #f0f0f0 !important;
  }

  .driver-nav .nav-tabs.nav-item {
    margin-right: 4px;
    margin-bottom: -1px !important;
    line-height: 18px !important;
  }

  .driver-nav .nav-tabs .dropdown-menu {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
  .badge {
    top: -6px;
    font-weight: normal;
  }

  .user-profile {
    width: 44px;
    height: 44px;
    cursor: zoom-in;
  }

  .bt-whatsapp {
    background-color: #25d366;
    border-color: #25d366;
    color: #fff;
  }

  .card-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
