var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$apollo.queries.driver.loading
    ? _c(
        "div",
        [
          _c("hr"),
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c("b-col", { staticClass: "header" }, [
                _c(
                  "h4",
                  { staticClass: "mt-1" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("drivers.titles.residenceTitle")) +
                        " "
                    ),
                    _c("badge-doc-status", {
                      attrs: {
                        "parent-component": "address",
                        status: _vm.addressDocs.length
                          ? _vm.addressDocs[0].status
                          : "PENDING",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("p", { staticClass: "card-text mb-4" }, [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("drivers.labels.driverName"))),
            ]),
            _c("br"),
            _vm.driver.name
              ? _c("strong", [_vm._v(_vm._s(_vm.driver.name))])
              : _c("strong", [
                  _vm._v(_vm._s(_vm.$t("drivers.texts.stillNotInfo"))),
                ]),
          ]),
          _c("p", { staticClass: "card-text mb-4" }, [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("drivers.labels.motherName"))),
            ]),
            _c("br"),
            _vm.driver.mother_name
              ? _c("strong", [_vm._v(_vm._s(_vm.driver.mother_name))])
              : _c("strong", [
                  _vm._v(_vm._s(_vm.$t("drivers.texts.stillNotInfo"))),
                ]),
          ]),
          _c("p", { staticClass: "card-text mb-4" }, [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("drivers.labels.fatherName"))),
            ]),
            _c("br"),
            _vm.driver.father_name
              ? _c("strong", [_vm._v(_vm._s(_vm.driver.father_name))])
              : _c("strong", [
                  _vm._v(_vm._s(_vm.$t("drivers.texts.stillNotInfo"))),
                ]),
          ]),
          _vm.addressDocs.length && _vm.addressDocs[0].reject_reasons
            ? _c("p", { staticClass: "card-text mb-4" }, [
                _c("strong", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("drivers.texts.rejectedReason") + ":"
                    ),
                  },
                }),
                _c("br"),
                _c(
                  "ul",
                  _vm._l(
                    _vm.addressDocs[0].reject_reasons,
                    function (reason, index) {
                      return _c("li", {
                        key: index,
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              `drivers.forms.documentRejectOptions.${reason}`
                            )
                          ),
                        },
                      })
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          _c("p", { staticClass: "card-text mb-4" }, [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("drivers.labels.address"))),
            ]),
            _c("br"),
            _vm.driver.address_street_name && _vm.driver.address_street_number
              ? _c("strong", [_vm._v(_vm._s(_vm.getFullAddress()))])
              : _c("strong", [
                  _vm._v(_vm._s(_vm.$t("drivers.texts.stillNotInfo"))),
                ]),
          ]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("modal-show-image", {
                    attrs: {
                      url:
                        _vm.addressDocs.length && _vm.addressDocs[0].url
                          ? _vm.addressDocs[0].url
                          : null,
                      title: _vm.getFullAddress(),
                      display:
                        _vm.addressDocs.length > 0 && _vm.addressDocs[0].url
                          ? 1
                          : 0,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "8" } },
                [
                  _c("map-google-maps", {
                    attrs: {
                      label: "Endereço de residência",
                      name: "driver-address",
                      display:
                        _vm.driver.address_street_name &&
                        _vm.driver.address_street_number
                          ? 1
                          : 0,
                      driver: this.getLocation(),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.$t("drivers.labels.chargeFile") } },
                    [
                      _c("b-form-file", {
                        ref: "driver_approval_address_file",
                        attrs: {
                          id: "input-files-address",
                          accept: "image/jpeg, image/png",
                          placeholder: _vm.$t("drivers.labels.choiceFile"),
                        },
                        on: { input: _vm.uploadDoc },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: { show: "", variant: "waning" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("drivers.labels.chargingAddress")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("b-progress", {
            attrs: { value: 100, variant: "warning", animated: "" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }