var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("driver-license-alert", { attrs: { driverId: _vm.id } }),
      _vm.profile
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "pb-4 justify-content-start" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "8", sm: "12" } },
                    [
                      _vm.profile.status !== "SIGNUP"
                        ? _c("driver-karma", {
                            attrs: { profile: _vm.profile },
                          })
                        : _vm._e(),
                      _c("b-img", {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modalDriverPhoto",
                            modifiers: { modalDriverPhoto: true },
                          },
                        ],
                        staticClass: "mr-2 mt-1 float-left user-profile",
                        attrs: {
                          src: _vm.profile.photo,
                          rounded: "",
                          "blank-color": "#C0C0C0",
                          title: _vm.$t("drivers.labels.showImage"),
                        },
                      }),
                      _c(
                        "h2",
                        { staticClass: "card-title m-0 mb-1" },
                        [
                          _c(
                            "b-link",
                            {
                              staticClass: "card-name mr-1",
                              attrs: {
                                to: `/drivers/profile/${_vm.newDriverId}`,
                                title: _vm.profile.name,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.profile.name ||
                                      _vm.$t("drivers.texts.anonymous")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.isActiveDriver
                        ? _c(
                            "div",
                            { staticClass: "small text-muted" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("drivers.labels.status")) +
                                  ": "
                              ),
                              _c(
                                "b-badge",
                                {
                                  attrs: {
                                    variant: _vm.setStatusBadge(
                                      _vm.profile.status
                                    ),
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.profile.status) + " ")]
                              ),
                              _vm._v(
                                " - " +
                                  _vm._s(_vm.$t("drivers.labels.register")) +
                                  " "
                              ),
                              _vm.profile.created_at
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("moment")(
                                          _vm.profile.created_at,
                                          "from",
                                          true
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "0 " +
                                        _vm._s(_vm.$t("drivers.labels.days"))
                                    ),
                                  ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("drivers.labels.behind")) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "small text-muted" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("drivers.labels.situationRegister")
                                  ) +
                                  ": "
                              ),
                              _vm.profile.status === "BANNED"
                                ? _c(
                                    "b-badge",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: { variant: "danger" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("up")(
                                              _vm.$t("drivers.labels.banned")
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "b-badge",
                                    { attrs: { variant: "warning" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.profile.step) + " "
                                      ),
                                    ]
                                  ),
                              _vm._v(
                                " - " +
                                  _vm._s(_vm.$t("drivers.labels.register")) +
                                  " "
                              ),
                              _vm.profile.created_at
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("moment")(
                                          _vm.profile.created_at,
                                          "from",
                                          true
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "0 " +
                                        _vm._s(_vm.$t("drivers.labels.days"))
                                    ),
                                  ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("drivers.labels.behind")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _vm.resetProfile
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-md-right text-sm-center",
                          attrs: { md: "4", sm: "12" },
                        },
                        [
                          _vm.$route.query.redir && !_vm.modal
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    variant: "secondary",
                                    "data-test": "driver-header__btn-back",
                                  },
                                  on: { click: _vm.goBack },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-angle-left" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("drivers.buttons.back")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          ["SIGNUP"].includes(_vm.profile.status)
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "bt-whatsapp mr-2",
                                  attrs: {
                                    href:
                                      "https://web.whatsapp.com/send?phone=" +
                                      _vm.profile.phone_number,
                                    target: "_blank",
                                    itle: _vm.$t("drivers.labels.sendWhats"),
                                    "data-test": "driver-header__btn-whatsapp",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-whatsapp" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("drivers.buttons.whatsApp")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-button-group",
                            { staticClass: "mr-2" },
                            [
                              [
                                "LEAD",
                                "PROSPECT",
                                "DOCS_PENDING",
                                "DOCS_REJECTED",
                                "DOCS_CHECKING",
                              ].includes(_vm.profile.step)
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "secondary",
                                        to: `/drivers/profile/${_vm.newDriverId}`,
                                        "data-test":
                                          "driver-header__btn-account",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("drivers.buttons.profile")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.profile.booking && _vm.profile.booking.car
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        to: `/fleet/${_vm.profile.booking.car.id}`,
                                        "data-test": "driver-header__btn-car",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("drivers.buttons.car")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    "data-test": "driver-header__btn-edit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.goTo()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("drivers.buttons.edit")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.newScheduleModal &&
              ["ACTIVE", "INACTIVE", "BANNED", "REJECTED"].includes(
                _vm.profile.status
              ) &&
              _vm.resetProfile
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right driver-nav",
                          attrs: { md: "12", sm: "12" },
                        },
                        [
                          _c(
                            "b-nav",
                            { attrs: { tabs: "", align: "left" } },
                            [
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    to: `/drivers/profile/${_vm.newDriverId}/bookings`,
                                    "data-test": "driver-header__btn-bookings",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "driversBooking.words.location"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    to: `/drivers/profile/${_vm.newDriverId}/wallet`,
                                    "data-test": "driver-header__btn-wallet",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("payments.labels.wallet")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    to: `/drivers/profile/${_vm.newDriverId}/fines`,
                                    "data-test": "driver-header__btn-fines",
                                  },
                                },
                                [
                                  _vm._v(" Multas "),
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.profile.total_fines) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-nav-item-dropdown",
                                {
                                  attrs: {
                                    id: "driver-messages",
                                    text: "Mensagem",
                                    "toggle-class": "nav-link-custom",
                                    left: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        href:
                                          "https://web.whatsapp.com/send?phone=" +
                                          _vm.profile.phone_number,
                                        target: "_blank",
                                        "data-test":
                                          "driver-header__select-whatsapp",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-whatsapp",
                                      }),
                                      _vm._v(" WhatsApp "),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        "data-test":
                                          "driver-header__select-sms",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.newMessage()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-comments",
                                      }),
                                      _vm._v(" SMS+Email "),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.checkScheduleView({
                                show: "RULE:SCHEDULE:VIEW",
                              })
                                ? _c(
                                    "b-nav-item-dropdown",
                                    {
                                      attrs: {
                                        id: "driver-messages",
                                        left: "",
                                        text: "Agendamento",
                                        "toggle-class": "nav-link-custom",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            "data-test":
                                              "driver-header__select-whatsapp",
                                          },
                                          on: {
                                            click:
                                              _vm.openModalDeliveryCarSchedule,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$i18n.t(
                                                  "drivers.titles.delivery"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            "data-test":
                                              "driver-header__select-whatsapp",
                                          },
                                          on: {
                                            click:
                                              _vm.openModalDevolutionCarSchedule,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$i18n.t(
                                                  "drivers.titles.devolution"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            "data-test":
                                              "driver-header__select-whatsapp",
                                          },
                                          on: {
                                            click:
                                              _vm.openModalDeliveryAndDevolutionCarSchedule,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$i18n.t(
                                                  "drivers.titles.deliveryAndDevolution"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modalDriverPhoto",
                    lazy: "",
                    "no-fade": "",
                    size: "md",
                    title: _vm.profile.name,
                    "hide-footer": true,
                    "body-class": "p-1",
                  },
                },
                [
                  _c("b-img", {
                    attrs: {
                      src: _vm.profile.photo,
                      rounded: "bottom",
                      "fluid-grow": "",
                      "blank-color": "#C0C0C0",
                      alt: _vm.profile.name,
                    },
                  }),
                ],
                1
              ),
              _c("contact-driver", {
                attrs: { modal_data: _vm.contact_modal_data },
              }),
              _vm.newScheduleModal ||
              ["SIGNUP"].includes(_vm.profile.status) ||
              !_vm.resetProfile
                ? _c(
                    "b-card",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "3", sm: "12" } }, [
                            _c("p", { staticClass: "card-text" }, [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("drivers.labels.lastUpdate"))
                                ),
                              ]),
                              _c("br"),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("format_from_now")(
                                      _vm.profile.updated_at
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("b-col", { attrs: { md: "3", sm: "12" } }, [
                            _c("p", { staticClass: "card-text" }, [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("drivers.labels.phone")) +
                                    " "
                                ),
                                _vm.profile.phone_number_verified
                                  ? _c("i", {
                                      staticClass:
                                        "fa fa-check-circle text-success",
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "fa fa-exclamation-triangle text-danger",
                                    }),
                              ]),
                              _c("br"),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("format_phone")(
                                      _vm.profile.phone_number
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("b-col", { attrs: { md: "3", sm: "12" } }, [
                            _c("p", { staticClass: "card-text" }, [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("drivers.labels.email")) +
                                    " "
                                ),
                                _vm.profile.email_verified
                                  ? _c("i", {
                                      staticClass:
                                        "fa fa-check-circle text-success",
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "fa fa-exclamation-triangle text-danger",
                                    }),
                              ]),
                              _c("br"),
                              _c("strong", [_vm._v(_vm._s(_vm.profile.email))]),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "3", sm: "12" } },
                            [
                              _c("p", { staticClass: "card-text" }, [
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("drivers.labels.document"))
                                  ),
                                ]),
                                _c("br"),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("format_document")(_vm.profile.cpf)
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._l(
                                _vm.usersAcessingDrivers.filter(
                                  (u) => u.driverId === _vm.id
                                ),
                                function (obj) {
                                  return _c(
                                    "b-badge",
                                    {
                                      key: obj.id,
                                      attrs: {
                                        variant: "warning",
                                        title: obj.email,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(obj.email.split("@")[0]) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c("br"),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }