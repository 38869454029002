<script>
import gql from 'graphql-tag';
export default {
  name: 'Rejected',
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    payment: null,
  }),
  apollo: {
    driver: {
      query: gql `
        query DriverGET($id: ID!) {
          driver(id: $id) {
            id
            cpf
            name
            mother_name
            father_name
            license_number
            license_first_issued_at
            license_expired_at
            license_category
            license_points
            license_status
            license_status_detail
            license_remarks
            license_has_permission
            license_state
          }
        }
      `,
      variables() {
        return {
          id: this.driverId,
        };
      },
      update(data) {
        const {
          driver
        } = data;
        return driver;
      },
    },
  },
};
</script>

<template>
  <b-card v-if="!$apollo.queries.driver.loading" class="mb-5">
    <h4 class="font-weight-bold">
      {{ $t('drivers.labels.rejectedRegister') }}
    </h4>
    <p>{{ $t('drivers.labels.notDriver') }}</p>
    <div>
      <b-alert v-if="driver.license_status === 'EXPIRED'" show variant="danger">
        {{ driver.license_status_detail }}
      </b-alert>
      <b-alert v-else show variant="warning">
        {{ driver.license_status_detail }}
      </b-alert>
      <b-row>
        <!--content-->
        <b-col md="4">
          <p class="card-text">
            <span class="text-muted">
              {{ $t('drivers.labels.licenceNumber') }}
              <span v-if="driver.license_status !== 'CHECKING'">
                <i v-if="driver.license_status === 'ACTIVE'" class="fa fa-check-circle text-success" />
                <i v-else class="fa fa-exclamation-triangle text-danger" />
              </span>
            </span>
            <br>
            <strong>{{ driver.license_number || 'Não informado' }}</strong>
          </p>
          <p class="card-text">
            <span class="text-muted">
              {{ $t('drivers.labels.licencePoints') }}
              <span v-if="driver.license_status !== 'CHECKING'">
                <i v-if="driver.license_points < 15" class="fa fa-check-circle text-success" />
                <i v-else class="fa fa-exclamation-triangle text-danger" />
              </span>
            </span>
            <br>
            <strong> {{ driver.license_points }} {{ $t('drivers.labels.points') }} </strong>
          </p>

          <p class="card-text">
            <span class="text-muted">
              {{ $t('drivers.labels.category') }} 
              <span v-if="driver.license_status !== 'CHECKING'">
                <i v-if="['B', 'AB', 'C', 'D', 'E'].includes(driver.license_category)" class="fa fa-check-circle text-success" />
                <i v-else class="fa fa-exclamation-triangle text-danger" />
              </span>
            </span>
            <br>
            <strong>{{ driver.license_category | empty }}</strong>
          </p>
        </b-col>

        <!--right content-->
        <b-col md="4">
          <p class="card-text">
            <span class="text-muted">
              {{ $t('drivers.labels.validate') }} 
              <span v-if="driver.license_status !== 'CHECKING'">
                <i v-if="
                  ['EXPIRED', 'CPF_RENTAL_DENATRAN_DONT_MATCH'].includes(driver.license_status)
                " class="fa fa-exclamation-triangle text-danger"
                />
                <i v-else class="fa fa-check-circle text-success" />
              </span>
            </span>
            <br>
            <strong>{{ driver.license_expired_at | format_date | empty }}</strong>
          </p>

          <p class="card-text">
            <span class="text-muted">
              {{ $t('drivers.labels.hasEar') }} 
              <span v-if="driver.license_status !== 'CHECKING'">
                <i v-if="driver.license_has_permission" class="fa fa-check-circle text-success" />
                <i v-else class="fa fa-exclamation-triangle text-danger" />
              </span>
            </span>
            <br>
            <strong>{{
              ['CHECKING'].includes(driver.license_status)
                ? '-'
                : driver.license_has_permission
                  ? $t('drivers.labels.yes')
                  : $t('drivers.labels.no')
            }}</strong>
          </p>
        </b-col>

        <!--right content-->
        <b-col md="4">
          <p class="card-text">
            <span class="text-muted">{{ $t('drivers.labels.stateRegister') }}</span><br>
            <strong>{{ driver.license_state | empty }}</strong>
          </p>

          <p class="card-text">
            <span class="text-muted">
              {{ $t('drivers.labels.timeLicence') }}
              <span v-if="driver.license_status !== 'CHECKING'">
                <i v-if="this.$moment().diff(driver.license_first_issued_at, 'years') > 0" class="fa fa-check-circle text-success" />
                <i v-else class="fa fa-exclamation-triangle text-danger" />
              </span>
            </span>
            <br>
            <strong>{{ driver.license_first_issued_at | format_from_now | empty }}</strong>
          </p>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
