<template>
  <div class="text-center mt-5">
    <hr>
    <b-button id="btn-reject-driver" v-b-modal.driver-reject-modal class="pl-3 pr-3 mr-3"
              size="md" variant="dark"
    >
      {{ $t('drivers.buttons.driverReject') }}
    </b-button>

    <b-button v-if="enabledApproveDriverButton" id="btn-approve-docs" class="pl-3 pr-3 mr-3"
              size="md" variant="success" @click="approveDriver"
    >
      {{ $t('drivers.buttons.driverApprove') }}
    </b-button>

    <b-modal id="driver-reject-modal" size="md" :title="$t('drivers.titles.driverReject')"
             :cancel-title="$t('drivers.buttons.cancel')"
             :ok-title="$t('drivers.buttons.driverReject')"
             :ok-disabled="!driverRejectSelect" ok-variant="danger" @ok="disapproveDriver()"
    >
      <b-form-select v-model="driverRejectSelect" :options="driverRejectOptions"/>
    </b-modal>
  </div>
</template>

<script>
import DOCS_APPROVE_DRIVER from '@graphql/driver-documents/mutations/approve-documentation.gql';
import DOCS_DISAPPROVE_DRIVER from '@graphql/driver-documents/mutations/disapprove-driver.gql';
import { isMexico } from '@utils/helper-region'

export default {
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      driverRejectSelect: null,
    };
  },
  computed: {
    addressDocs() {
      return this.$store.getters['driverDocument/address'];
    },
    driverRejectOptions() {
      let options = [
        { text: this.$t('drivers.forms.driverRejectOptions.null'), value: null },
        {
          text: this.$t('drivers.forms.driverRejectOptions.COVERAGE_AREA_REFUSED_MANUALLY'),
          value: 'COVERAGE_AREA_REFUSED_MANUALLY',
        },
        {
          text: this.$t('drivers.forms.driverRejectOptions.RISK_AREA_REFUSED_MANUALLY'),
          value: 'RISK_AREA_REFUSED_MANUALLY',
        },
        {
          text: this.$t('drivers.forms.driverRejectOptions.CNH_REFUSED_MANUALLY'),
          value: 'CNH_REFUSED_MANUALLY',
        },
        {
          text: this.$t('drivers.forms.driverRejectOptions.FINANCIAL_REFUSED_MANUALLY'),
          value: 'FINANCIAL_REFUSED_MANUALLY',
        },
        {
          text: this.$t('drivers.forms.driverRejectOptions.DRIVER_DENIED_BY_AGE'),
          value: 'DRIVER_DENIED_BY_AGE',
        },

      ]

      if (isMexico()) options.push({
        text: this.$t('drivers.forms.driverRejectOptions.HAS_LEGAL_AGE_MANUALLY'),
        value: 'HAS_LEGAL_AGE_MANUALLY',
      })

      return options
    },
    enabledApproveDriverButton() {
      return this.$store.getters['user/groups'].includes('ACL:BUTTON:APPROVED:DRIVER');
    },
  },
  mounted() {
    this.$store.dispatch('driverDocument/reset');
    this.$store.dispatch('driverDocument/getByDriverId', {
      driverId: this.driverId,
      conditionals: {
        status: ['PENDING', 'APPROVED', 'REJECTED', 'SENT'],
      },
    });
  },
  methods: {
    approveDriver() {
      this.$swal(this.swalConfigApproveDriver())
      .then(this.handleApproveDriveSuccess)
      .catch(this.handleApproveError);
    },
    disapproveDriver() {
      const configSwal = this.swalConfigDisapproveDriver();
      this.$swal(configSwal)
      .then(this.handleDisapproveDriverSuccess)
      .catch(this.handleDisapproveError);
    },

    handleApproveDriveSuccess(data) {
      if (data.dismiss) return;
      const configSwalSuccess = this.swalConfigApproveDriverSuccess();
      this.$swal(configSwalSuccess);
      this.$router.push({
        name: 'DriversSignupDocsChecking',
      });
    },
    handleApproveError() {
      const configSwalFail = {
        type: 'error',
        title: this.$t('drivers.titles.docsRejected'),
        text: ':(',
      };

      this.$swal(configSwalFail);
    },
    handleDisapproveDriverSuccess(data) {
      if (data.dismiss) return;
      const configSwalSuccess = this.swalConfigDisapproveDriverSuccess();

      this.$swal(configSwalSuccess);
      this.$router.push({
        name: 'DriversSignupDocsChecking',
      });
    },
    handleDisapproveError() {
      const configSwalFail = {
        type: 'error',
        title: this.$t('drivers.titles.dontRejected'),
        text: ':(',
      };
      this.$swal(configSwalFail);
    },
    handleGqlApproveDriver() {
      return this.$apollo.mutate({
        mutation: DOCS_APPROVE_DRIVER,
        variables: {
          driver: this.driverId,
        },
      });
    },
    handleGqlDisapproveDriver() {
      return this.$apollo.mutate({
        mutation: DOCS_DISAPPROVE_DRIVER,
        variables: {
          driver: this.driverId,
          reject_reason: this.driverRejectSelect,
        },
      });
    },

    swalConfigApproveDriver() {
      return {
        type: 'warning',
        title: this.$t('drivers.titles.docApproved'),
        text: this.$t('drivers.texts.toProceed'),
        showConfirmButton: true,
        confirmButtonText: this.$t('drivers.buttons.approveVhtml'),
        showCancelButton: true,
        cancelButtonText: this.$t('drivers.buttons.cancelVhtml'),
        showLoaderOnConfirm: true,
        onOpen: () => {
          const swalConfirm = document.getElementById('swal-approve-confirm');
          const swalClose = document.getElementById('swal-approve-close');

          if (swalConfirm) {
            swalConfirm.parentNode.id = 'btn-swal-approve-confirm';
          }

          if (swalClose) {
            swalClose.parentNode.id = 'btn-swal-approve-cancel';
          }
        },
        preConfirm: data =>
          new Promise((resolve, reject) =>
            this.handleGqlApproveDriver()
            .then(() => resolve(true))
            .catch(() => reject(data)),
          ),
      };
    },
    swalConfigApproveDriverSuccess() {
      return {
        type: 'success',
        title: this.$t('drivers.titles.driverApproved'),
        timer: 2000,
        showConfirmButton: false,
        text: '',
        onOpen: () => {
          const swalSuccess = document.getElementById('swal-approved-success');
          if (swalSuccess) {
            swalSuccess.parentNode.id = 'btn-swal-approved-succsess';
          }
        },
      };
    },
    swalConfigDisapproveDriver() {
      return {
        type: 'warning',
        title: this.$t('drivers.buttons.driverReject'),
        text: this.$t('drivers.texts.driverBlock'),
        showConfirmButton: true,
        confirmButtonText: this.$t('drivers.buttons.driverReject'),
        showCancelButton: true,
        cancelButtonText: this.$t('drivers.buttons.closeVhtml'),
        showLoaderOnConfirm: true,
        onOpen: () => {
          const confirmButton = document.getElementById('swal-reject-confirm');
          if (confirmButton) {
            confirmButton.parentNode.id = 'btn-swal-reject-confirm';
          }

          const closeButton = document.getElementById('swal-reject-close');
          if (closeButton) {
            closeButton.parentNode.id = 'btn-swal-reject-close';
          }
        },
        preConfirm: () =>
          new Promise(resolve => {
            this.handleGqlDisapproveDriver()
            .then(() => resolve(true))
            .catch(() => resolve(false));
          }),
      };
    },
    swalConfigDisapproveDriverSuccess() {
      return {
        type: 'success',
        title: this.$t('drivers.titles.driverReproved'),
        text: '',
        confirmButtonText: '<a id="swal-rejected-docs">OK</a>',
        onOpen: () => {
          const swalReject = document.getElementById('swal-rejected-docs');
          if (swalReject) {
            swalReject.parentNode.id = 'btn-swal-rejected-docs';
          }
        },
      };
    },
  },
};
</script>
