var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "address-photo" }, [
    _vm.display
      ? _c("img", {
          directives: [
            {
              name: "viewer",
              rawName: "v-viewer",
              value: _vm.options,
              expression: "options",
            },
          ],
          attrs: { src: _vm.url, alt: _vm.title },
        })
      : _c("div", [_vm._v(" Ainda não recebemos o comprovante ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }