<template>
  <div>
    <!-- Loading state or score null -->
    <div v-if="!!profile.score == false">
      <b-badge id="popover-kovi-score" class="mr-2 mt-1 float-left user-score" variant="default">
        -
      </b-badge>
      <b-popover target="popover-kovi-score" triggers="hover" placement="bottom">
        <template v-slot:title>
          Kovi Karma<sup>Beta</sup>
        </template>
        {{ $t('drivers.karma.no_transactions') }}
      </b-popover>
    </div>
    
    <!-- Valid score -->
    <div v-if="!!profile.score">
      <b-badge id="popover-kovi-score" class="mr-2 mt-1 float-left user-score" :variant="checkScore(profile.score.kovi_score)">
        <!-- success, info, warning, danger -->
        {{ profile.score.kovi_score }}
      </b-badge>
      <b-popover target="popover-kovi-score" triggers="hover" placement="bottom">
        <template v-slot:title>
          Kovi Karma<sup>Beta</sup>
        </template>
        {{ $t('drivers.karma.paid_on_time') }}: 
          <strong>{{ profile.transaction_summary.total_transactions_on_time }}</strong>/{{ profile.transaction_summary.total_transactions }}<br>
        <hr>
        {{ $t('drivers.karma.paid_overdue') }}: 
          <strong>{{ profile.transaction_summary.total_transactions_overdue }}</strong>/{{ profile.transaction_summary.total_transactions }}<br>
        {{ $t('drivers.karma.probably_to_overdue') }}: 
          <strong>{{ 100-profile.score.kovi_score }}%</strong><br>
        <span v-if="profile.transaction_summary.avg_transactions_overdue_days">
          {{ $t('drivers.karma.avg_overdue') }}: 
          <strong>{{ profile.transaction_summary.avg_transactions_overdue_days }} {{ profile.transaction_summary.avg_transactions_overdue_days === 1 ? $t('drivers.karma.avg_overdue_day') : $t('drivers.karma.avg_overdue_days') }}</strong><br>
        </span>
        <hr>
        {{ $t('drivers.karma.total_recurrencies') }}: 
          <strong>{{ profile.transaction_summary.debit_recurrencies | money }} </strong><br>
        {{ $t('drivers.karma.total_fines') }}: 
          <strong>{{ profile.transaction_summary.debit_fines | money }} </strong><br>
        {{ $t('drivers.karma.total_maintenances') }}:
          <strong>{{ profile.transaction_summary.debit_maintenances | money }} </strong><br>
        {{ $t('drivers.karma.total_others') }}: 
          <strong>{{ profile.transaction_summary.debit_others | money }} </strong><br>
        <hr>
        {{ $t('drivers.karma.total_debits') }}: 
          <strong>{{ profile.transaction_summary.debit_total | money }} </strong>
      </b-popover>
    </div>
  </div>
</template>

<style style="scss" scoped>
  .user-score {
    width: 64px;
    height: 64px;
    cursor: help;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 44px;
    padding-top: 10px;

    hr {
      margin: 3px 0;
      padding: 0;
    }
  }
  .badge-default {
    background-color: #d8d8d8;
    color: #929292;
  }
</style>

<script>
  export default {
    name: 'DriverKarma',
    props: {
      profile: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    methods: {
      checkScore(val) {
        // success, info, warning, danger
        if (val >= 90) return 'success';
        else if (val < 90 && val >= 70) return 'info';
        else if (val < 70 && val >= 40) return 'warning';
        else return 'danger';
      }
    }
  };
</script>