<script>
export default {
  name: 'DriverAddressMap',
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
      default: 'Endereço',
    },
    driver: {
      type: Object,
      require: true,
    },
  },
  data: function() {
    return {
      mapName: (this.name || 'google') + '-map',
      map: null,
    };
  },
  mounted: function() {
    const element = document.getElementById(this.mapName);
    const that = this;
    // eslint-disable-next-line
    const bounds = new google.maps.LatLngBounds();
    // eslint-disable-next-line
    const position = new google.maps.LatLng(this.driver.location[0], this.driver.location[1]);
    let options = {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      flat: true,
      zoom: 15,
      center: position,
      // eslint-disable-next-line
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    // eslint-disable-next-line
    this.map = new google.maps.Map(element, options);

    // eslint-disable-next-line
    const kmlDataFile = 'https://docs.kovi.us/safety/safety-zones-sp.kmz?q=' + new Date().getTime();
    // eslint-disable-next-line
    const kmlLayer = new google.maps.KmlLayer(kmlDataFile, {
      suppressInfoWindows: true,
      preserveViewport: true,
      map: this.map,
    });
    // eslint-disable-next-line
    const marker = new google.maps.Marker({
      position,
      title: this.label,
      map: this.map,
    });
    marker.addListener('click', () => {
      that.$router.push({ path: '/driver/' + this.driver.id });
    });
  },
};
</script>

<template>
  <div :id="mapName" class="google-map" />
</template>

<style scoped>
.google-map {
  width: 100%;
  height: 200px;
  margin: 0 auto;
  background: gray;
}
</style>
