var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("hr"),
      _c("h4", { staticClass: "mt-1 mb-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("drivers.titles.historyRacing")) + " "),
      ]),
      _c(
        "b-row",
        _vm._l(_vm.listApps, function (app, index) {
          return _c(
            "b-col",
            { key: index, staticClass: "app-item mb-4", attrs: { md: "4" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c(
                        "b-alert",
                        {
                          staticClass: "rides mb-1 text-center",
                          attrs: {
                            show: "",
                            variant: app.approved ? "success" : "danger",
                          },
                        },
                        [_vm._v(" " + _vm._s(app.rides) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "small text-center text-muted" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("drivers.titles.driversRacing")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "pl-0", attrs: { md: "8" } }, [
                    _c("h4", { staticClass: "mt-2 mb-0 font-weight-bold" }, [
                      _vm._v(" " + _vm._s(app.name) + " "),
                    ]),
                    _c(
                      "p",
                      { staticClass: "small text-uppercase text-muted" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("drivers.titles.drivers")) +
                            " " +
                            _vm._s(app.status) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }