<template>
  <b-badge :id="getId" :variant="variantStatus" class="text-badge">
    {{ status }}
  </b-badge>
</template>

<script>
export default {
  name: 'BadgeDocStatus',
  props: {
    status: {
      type: String,
      required: true,
    },
    parentComponent: {
      type: String,
      required: false,
      default: 'proof-docs',
    },
  },
  computed: {
    getId() {
      return `badge-status-${this.parentComponent.toLowerCase().replace(' ', '-')}`;
    },
    variantStatus() {
      switch (this.status) {
      case 'PENDING':
        return 'warning';
      case 'SENT':
        return 'info';
      case 'APPROVED':
        return 'success';
      case 'REJECTED':
        return 'danger';
      default:
        return 'light';
      }
    },
  },
};
</script>

<style scoped>
.text-badge {
  font-size: 55%;
  font-weight: 500;
  vertical-align: top;
}
</style>
