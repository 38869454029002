<script>
import BadgeDocStatus from './badge-doc-status';
import ModalShowImage from './modal-show-image';
import gql from 'graphql-tag';

export default {
  name: 'DriverViewApprovalCnh',
  components: {
    BadgeDocStatus,
    ModalShowImage,
  },
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      document_title: this.$t('drivers.titles.documentTitle')
    };
  },
  apollo: {
    driver: {
      query: gql `
        query DriverGET($id: ID!) {
          driver(id: $id) {
            id
            cpf
            name
            mother_name
            father_name
            license_number
            license_first_issued_at
            license_expired_at
            license_category
            license_points
            license_status
            license_status_detail
            license_remarks
            license_has_permission
            license_state
          }
        }
      `,
      variables() {
        return {
          id: this.driverId,
        };
      },
      update(data) {
        const {
          driver
        } = data;
        return driver;
      },
    },
  },
  computed: {
    listCnhDoc() {
      return this.$store.getters['driverDocument/cnh'];
    },
  },
  methods: {
    loadDocs() {
      this.$store.dispatch('driverDocument/getByDriverId', {
        driverId: this.driverId,
        conditionals: {
          status: ['PENDING', 'APPROVED', 'REJECTED', 'SENT'],
        },
      });
    },

    async uploadDoc(file) {
      try {
        const objectUpload = await this.$store.dispatch('driverDocument/uploadCnh', {
          driverId: this.driverId,
          file,
        });
        if (objectUpload) {
          if (objectUpload.status === 'success') {
            this.$refs.driver_approval_cnh_file.reset();
            this.$snotify.success($t('drivers.texts.uploadFileSuccess'), this.document_title);
            this.loadDocs();
          } else {
            this.$snotify.error($t('drivers.texts.uploadFileError'), this.document_title);
          }
        }
      } catch (e) {
        this.$snotify.error($t('drivers.texts.uploadFileError'), this.document_title);
      }
    },

    async approveDoc(documentEntity) {
      try {
        await documentEntity.approve();
        this.$snotify.success($t('drivers.titles.docsApproved'), this.document_title);
        this.loadDocs();
      } catch (e) {
        this.$snotify.error($t('drivers.texts.approveError'), this.document_title);
      }
    },

    async disapproveDoc(documentEntity) {
      try {
        await documentEntity.disapprove();
        this.$snotify.error($t('drivers.titles.docReproved'), this.document_title);
        this.$router.push({
          name: 'drivers-signup-docs-checking',
        });
      } catch (e) {
        this.$snotify.error($t('drivers.texts.errorReprovedDocs'), this.document_title);
      }
    },

    driverLicenseData() {
      const expired_at = this.$moment(this.driver.license_expired_at).format('DD/MM/YYYY');
      return `CNH ${this.driver.license_number} / CPF ${this.driver.cpf} / Validade ${expired_at}`;
    },
  },
};
</script>

<template>
  <div v-if="!$apollo.queries.driver.loading" class="area-approved-cnh">
    <hr>
    <b-row class="mb-4">
      <b-col md="6" class="header">
        <h4 class="mt-1">
          {{ $t('drivers.titles.documentTitle') }}
          <badge-doc-status parent-component="cnh" :status="listCnhDoc.length ? listCnhDoc[0].status : 'PENDING'" />
        </h4>
      </b-col>
      <b-col class="text-right" md="6">
        <div v-if="listCnhDoc.length && listCnhDoc[0].url && (driver.license_number && driver.license_points !== '-')">
          <b-button id="btn-approve-cnh" :disabled="['PENDING', 'APPROVED'].includes(listCnhDoc[0].status)" variant="success"
                    @click="approveDoc(listCnhDoc[0])"
          >
            {{ $t('drivers.buttons.approve') }}
          </b-button>
          <b-button id="btn-reject-cnh" :disabled="['PENDING', 'REJECTED'].includes(listCnhDoc[0].status)" variant="danger"
                    class="ml-3" @click="disapproveDoc(listCnhDoc[0])"
          >
            {{ $t('drivers.buttons.reprove') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-alert :show="['CHECKING'].includes(driver.license_status)" variant="info">
      {{ $t('drivers.texts.waitingInfoDenatran') }}
    </b-alert>
    <b-alert :show="['CANCELED', 'SUSPENDED'].includes(driver.license_status)" variant="danger">
      {{ $t('drivers.texts.docImpediments') }}
    </b-alert>
    <b-alert :show="['INVALID'].includes(driver.license_status)" variant="danger">
      {{ $t('drivers.texts.docInvalid') }}
    </b-alert>
    <b-alert :show="['EXPIRED'].includes(driver.license_status)" variant="danger">
      {{ $t('drivers.texts.docExpired') }}
    </b-alert>

    <b-row>
      <b-col md="4">
        <modal-show-image :url="listCnhDoc.length && listCnhDoc[0].url ? listCnhDoc[0].url : null" :title="driverLicenseData()" :display="listCnhDoc.length > 0 && listCnhDoc[0].url ? 1 : 0" />
      </b-col>

      <!--content-->
      <b-col md="4">
        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.licenceNumber') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="driver.license_status === 'ACTIVE'" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_number || $t('drivers.texts.notInfo') }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.licencePoints') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="driver.license_points < 15" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_points }} {{ $t('drivers.labels.points') }} </strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.validate') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="['EXPIRED', 'CPF_RENTAL_DENATRAN_DONT_MATCH'].includes(driver.license_status)" class="fa fa-exclamation-triangle text-danger" />
              <i v-else class="fa fa-check-circle text-success" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_expired_at | format_date | empty }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.hasEar') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="driver.license_has_permission" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>
            {{
              ['CHECKING'].includes(driver.license_status)
                ? '-'
                : driver.license_has_permission
                  ? $t('drivers.labels.yes')
                  : $t('drivers.labels.no')
            }}
          </strong>
        </p>
      </b-col>

      <!--right content-->
      <b-col md="4">
        <p class="card-text">
          <span class="text-muted">{{ $t('drivers.labels.stateRegister') }}</span>
          <br>
          <strong>{{ driver.license_state | empty }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.timeLicence') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="this.$moment().diff(driver.license_first_issued_at, 'years') > 0" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_first_issued_at | format_from_now | empty }}</strong>
        </p>

        <p class="card-text">
          <span class="text-muted">
            {{ $t('drivers.labels.category') }}
            <span v-if="driver.license_status !== 'CHECKING'">
              <i v-if="['B', 'AB', 'C', 'D', 'E'].includes(driver.license_category)" class="fa fa-check-circle text-success" />
              <i v-else class="fa fa-exclamation-triangle text-danger" />
            </span>
          </span>
          <br>
          <strong>{{ driver.license_category | empty }}</strong>
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col md="4">
        <b-form-group :label="$t('drivers.labels.chargeFile')">
          <b-form-file id="input-files-cnh" ref="driver_approval_cnh_file" accept="image/jpeg, image/png"
                       :placeholder="$t('drivers.labels.sendFile')" @input="uploadDoc"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
  <div v-else class="mt-4">
    <b-row>
      <b-alert show variant="waning" class="text-center">
        {{ $t('drivers.labels.chargingLicense') }}
      </b-alert>
    </b-row>
    <b-progress :value="100" variant="warning" animated />
  </div>
</template>

<style lang="scss" scoped>
.area-approved-cnh {
    z-index: 1;
}

.section-title {
    font-size: 12pt;
}

.cnh-photo {
    background-color: #d7d7d7;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    max-height: 185px;

    img {
        max-width: 100%;
    }
}

.label-inverse {
    p {
        margin-bottom: 3px;
    }
}

.rides {
    font-size: 11pt;
}

.text-line-height {
    p {
        margin-bottom: 3px;
    }
}

.status {
    margin-top: -28px;
    z-index: 0;
}
</style>
