var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "mb-5" },
    [
      _c("h4", { staticClass: "font-weight-bold" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("drivers.titles.driversDocsChecking")) + " "
        ),
      ]),
      _c(
        "driver-container-history-apps",
        {
          attrs: { "driver-id": _vm.$route.params.id },
          on: { shared: (apps) => (this.listApps = apps) },
        },
        [
          _vm.listApps && _vm.listApps.length > 0
            ? _c("driver-view-approval-history-apps", {
                attrs: { "list-apps": _vm.listApps },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.$regions.isBrazil()
        ? _c("driver-view-approval-cnh", {
            attrs: { "driver-id": _vm.driverId },
          })
        : _vm._e(),
      _vm.$regions.isMexico()
        ? _c("driver-view-approval-cnhMX", {
            attrs: { "driver-id": _vm.driverId },
          })
        : _vm._e(),
      _c("driver-view-approval-address", {
        attrs: { "driver-id": _vm.driverId },
      }),
      _c("driver-view-approval-actions", {
        attrs: { "driver-id": _vm.driverId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }